<template>
  <div class="duvidas">
    <Voltar />
    <h1 class="titulo">Como Participar</h1>

    <div class="" style="margin-top: 1.5em">
      <iframe
        src="https://player.twitch.tv/?channel=redeamericaetelevisao&parent=portalamericapremios.wserp.com.br"
        frameborder="0"
        allowfullscreen="true"
        scrolling="no"
        height="420"
        width="100%"
      ></iframe>
    </div>
  </div>
</template>
<script>
import Voltar from '../../components/Voltar'
export default {
  components: { Voltar }
}
</script>
